import React from 'react'
import { Link } from "gatsby"
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Helmet from 'react-helmet'
import { Container, Row, Col, Button, Form } from 'reactstrap'
import styled from 'styled-components'
import HiringSection from '../../components/Hiring'
import ContactForm from '../../components/ContactForm'
import FeatureWithImage from '../../components/FeatureWithImage'
import Layout from '../../components/Layout'

import macbookImage from '../../img/pages/technologies/nodejs/macbook-node-js-web-app.png'
import nodeWebAppImage from '../../img/pages/technologies/nodejs/node-js-web-app.png'
import nodeBenefitsImage from '../../img/pages/technologies/nodejs/node-benefits.png'

const CheckText = styled.div`
  position: relative; padding-left: 50px;padding-bottom:15px;line-height:1.8rem;
  &:before {
    position:absolute;
    left: 0;
    top: 5px;
    font-family: 'Font Awesome\\ 5 Free';
    font-weight: 900; display: inline-block; line-height: 1; font-style: normal;
    content: '\\F00C';
  }
`

export default class extends React.Component {

  render() {
    return (
      <Layout>
      <div className="what-we-do">
        <Helmet
          title="Node.js Software Development | Bishop Software"
          meta={[
            { name: 'description', content: 'We can build web apps with Node.js that are ideal for both startups and large companies. Let\'s talk.' },
            { name: 'keywords', content: 'nodejs,node,js,javascript,expressjs,software,development,web,design,mobile,consultancy,consulting,website,application' }
          ]}
        />
        <section style={{paddingTop: '75px', display: 'block', backgroundImage: 'linear-gradient(132deg, #2c3532 0%, #568c6c 73%, #78c498 90%, #edf5e0 100%)', color: '#fafafa'}}>
          <Header dropdownGradient='linear-gradient(#7ac99a 0px, #389583 100%)' section="what-we-do" />
          <Container className="text-center" style={{paddingTop: '1.875rem'}}>
            <div style={{margin: '55px 0 0 0'}}>
              <h1 style={{color: '#FFCB9B', fontSize: '40px', fontWeight: 300}}>Node.js Development Company</h1>
              <h3 style={{fontSize: '18px', marginTop: '35px', fontWeight: 400, lineHeight: '24px'}}>Developing large-scale, real-time, and scalable web and mobile apps using only JavaScript.</h3>
              <div style={{marginTop: '35px', paddingBottom: '55px'}}>
                <Link to="/contact" className="round btn btn-primary">Hire our Node.js Developers</Link>
              </div>
            </div>
          </Container>
        </section>

    <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(180deg, #fff 0, #fff 30%, #fefefe 90%, #eee 100%)'}}>
      <Container>
        <Row>
          <Col>
            <p>Node.js is an open source, cross-platform back-end technology for building server-side network web applications. It uses an event-driven, non-blocking I/O model and enables the development of apps with a fast back-end. This technology is so well-known today because it provides great opportunities to developers and increases the efficiency of the engineering teams.</p>
          </Col>
        </Row>
      </Container>
    </section>

    <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(180deg, #fff 0, #fff 30%, #fefefe 90%, #eee 100%)'}}>
      <Container>
        <Row>
          <Col>
            <FeatureWithImage title="What's Node.js?" imageSrc={nodeWebAppImage}>
              <p>Today Node.js plays an essential role in the solutions stack of different companies that depend on its special benefits. It’s a fast-growing and most extensive development technology. With over 4 million users worldwide and an annual growth rate of 100%, Node.js represents a multipurpose platform used for web applications, IoT, and enterprise.</p>
              <p>The main benefit is that it can manage thousands of existing connections. Finally, this technology helped transform the app development landscape at Walmart, GoDaddy, PayPal, NASA, helping them to continue with innovation in APIs, Web, mobile devices, and customer expectations.</p>
            </FeatureWithImage>
            <FeatureWithImage title="Why consider Node.js for your web app?" imageSrc={macbookImage} right={true}>Web apps built on Node.js are ideal for both startups and large companies. Mentors of web development say that Node.js is one of several technologies for the APIs that would be interesting to use and should be required to focus on. Moreover, it can be used to develop standard web applications on the server side, where it can control a large number of occurring connections in a non-blocking way.</FeatureWithImage>
            <FeatureWithImage title="Benefits" imageSrc={nodeBenefitsImage}>
              <p>Any business, from startup and SMBs to Fortune 500 companies, can benefit from the use of Node.js, which allows creating fast and scalable web apps, such as Uber, GoDaddy, and has also become the de facto standard at Dow Jones.</p>
              <ul>
                <li><strong>Increases development team productivity</strong> thanks to the availability of a variety of NPM modules.</li>
                <li><strong>Reduces costs for development.</strong> It’s highly-scalable and requires fewer resources to handle big loads as compared with other technologies.</li>
                <li><strong>Speeds time-to-market.</strong>  It’s very fast and increases the speed of the development process, increasing the speed of any framework it’s used with.</li>
                <li><strong>Sustainable.</strong> It allows building an app that handles numerous client applications and still works faultlessly.</li>
                <li><strong>Efficient.</strong> It uses Javascript, so the same language can be used both on the back-end and on the front-end. This means no language barriers between front-end and back-end development.</li>
              </ul>
            </FeatureWithImage>

          </Col>
        </Row>
      </Container>
    </section>

    <section style={{backgroundImage: 'linear-gradient(132deg, #244718 2%, #76AF63 100%)', color: '#ccc', fontSize: '20px', padding: '70px 0'}}>
      <Container>
        <h2 style={{color: '#fff', marginBottom: '45px', textAlign: 'center'}}>What Apps Can Be Built with Node.js?</h2>
        <Row>
          <Col xs="12" lg="4">
            <CheckText>Chat apps</CheckText>
          </Col>
          <Col xs="12" lg="4">
            <CheckText>Big data manipulation apps</CheckText>
          </Col>
          <Col xs="12" lg="4">
            <CheckText>Social networking apps</CheckText>
          </Col>
        </Row>
        <Row>
          <Col xs="12" lg="4">
            <CheckText>Games</CheckText>
          </Col>
          <Col xs="12" lg="4">
            <CheckText>Field service apps</CheckText>
          </Col>
          <Col xs="12" lg="4">
            <CheckText>Web portals</CheckText>
          </Col>
        </Row>
        <Row>
          <Col xs="12" lg="4">
            <CheckText>Image processing apps</CheckText>
          </Col>
          <Col xs="12" lg="4">
            <CheckText>Data storage apps</CheckText>
          </Col>
          <Col xs="12" lg="4">
            <CheckText>Back-end dashboards</CheckText>
          </Col>
        </Row>
        <Row>
          <Col xs="12" lg="5" className="offset-lg-1">
            <CheckText>Workforce and facility management apps</CheckText>
          </Col>
          <Col xs="12" lg="5">
            <CheckText>Content management apps</CheckText>
          </Col>
        </Row>
        
      </Container>
    </section>

    <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(176deg, #ddd 0, #fff 30%, #fff 90%, #f1f1f1 100%)'}}>
      <ContactForm />
    </section>

        <HiringSection />

        <Footer section="what-we-do" />
      </div>
  </Layout>
    )
  }
}
  